<template>
  <div>
    <div>
      <b-row>
        <b-col cols="6">
          <div id="stripoSettingsContainer">Loading...</div></b-col
        >
        <b-col cols="6"> <div id="stripoPreviewContainer"></div></b-col>
      </b-row>
      <button @click="save">save</button>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loadDemoTemplate(this.initPlugin);
  },
  // Utility methods
  methods: {
    save() {
      window.StripoApi.getTemplate(function (html, css) {
        console.log(html, css);
      });
    },
    async loadDemoTemplate(callback) {
      const $ = this;
      this.request(
        "GET",
        "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Trigger%20newsletter%20mockup/Trigger%20newsletter%20mockup.html",
        null,
        function (html) {
          $.request(
            "GET",
            "https://raw.githubusercontent.com/ardas/stripo-plugin/master/Public-Templates/Basic-Templates/Trigger%20newsletter%20mockup/Trigger%20newsletter%20mockup.css",
            null,
            function (css) {
              callback({ html: html, css: css });
            }
          );
        }
      );
    },
    request(method, url, data, callback) {
      var req = new XMLHttpRequest();
      req.onreadystatechange = function () {
        if (req.readyState === 4 && req.status === 200) {
          callback(req.responseText);
        } else if (req.readyState === 4 && req.status !== 200) {
          console.error(
            "Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values"
          );
        }
      };
      req.open(method, url, true);
      if (method !== "GET") {
        req.setRequestHeader("content-type", "application/json");
      }
      req.send(data);
    },

    initPlugin(template) {
      const apiRequestData = {
        emailId: "6251361f166bfd9e51e19a03",
        userId: "600a68cbbbc06742e4453517",
        groupId: "test",
      };
      const script = document.createElement("script");
      script.id = "stripoScript";
      script.type = "text/javascript";
      script.src = "https://plugins.stripo.email/static/latest/stripo.js";
      var $ = this;
      script.onload = function () {
        window.Stripo.init({
          settingsId: "stripoSettingsContainer",
          previewId: "stripoPreviewContainer",

          locale: "en",
          html: template.html,
          css: template.css,
          apiRequestData: apiRequestData,
          mergeTags: [
            {
              category: "MailChimp",
              entries: [
                {
                  label: "First Name",
                  value: "*|FNAME|*",
                },
              ],
            },
          ],
          getAuthToken: function (callback) {
            $.axios
              .post("https://plugins.stripo.email/api/v1/auth", {
                pluginId: "2d866fa0fd8e43dbaff4ffd219c09531",
                secretKey: "60faabfe0dcb43a7bfe016cbd7568830",
              })
              .then(({ data }) => {
                // console.log(data.data.token);
                callback(data.token);
              });
          },
        });
      };
      document.body.appendChild(script);
    },
  },
};
</script>

<style>
.nav-tabs.nav-justified {
  display: block !important;
}
</style>
