const Setting = {
  path: "",
  name: "",
  redirect: "/setting",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/setting",
      redirect: "/setting",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Setting",
      meta: {
        requiresAuth: true,
        activeUrl: "/setting",

        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/setting"),
          meta: {
            activeUrl: "/setting",
            isChildren: true,
            requiresAuth: true,

            lv: 2,
          },
        },
        {
          path: "policy",
          name: "Setting Detail",
          component: () =>
            import("@/views/pages/setting/settingPage/DetailPolicy.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "bussinessinfo",
          name: "Business Information",
          component: () =>
            import("@/views/pages/setting/settingPage/BusinessInfo.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "pdpasetting",
          name: "Email Template",
          component: () =>
            import("@/views/pages/setting/settingPage/EmailPDPA.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "membership",
          name: "Member",
          component: () =>
            import("@/views/pages/setting/settingPage/SettingMemberShip.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "term",
          name: "Term and condition",
          component: () =>
            import("@/views/pages/setting/settingPage/DetailTerm.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "line",
          name: "Line Liff",
          component: () => import("@/views/pages/setting/settingPage/Line"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "general",
          name: "System",
          component: () => import("@/views/pages/setting/settingPage/General"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "",
          name: "",
          redirect: "ecommerce",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "ecommerce",
              name: "Ecommerce",
              redirect: "ecommerce",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Ecommerce"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Ecommerce Detail",
                  redirect: "/setting/ecommerce",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Ecommerce/detail.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    {
                      path: ":LogID",
                      name: "Log Detail",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Ecommerce/LogDetail.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "booking",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "booking",
              name: "Booking Setting",
              redirect: "Booking",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Booking"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Booking Setting Detail",
                  redirect: "/setting/booking",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Booking/detail"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                    //   path: ":LogID",
                    //   name: "Log Detail",
                    //   component: () =>
                    //     import(
                    //       "@/views/pages/setting/settingPage/Ecommerce/LogDetail.vue"
                    //     ),
                    //   meta: {
                    //     requiresAuth: true,
                    //
                    //     activeUrl: "/setting",
                    //     isChildren: true,
                    //     lv: 2,
                    //   },
                    // },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "payment-type",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "payment-type",
              name: "Payment Type",
              redirect: "payment-type",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/PaymentType"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Payment Type Detail",
                  redirect: "/setting/payment-type",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/PaymentType/detail"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "branch-group",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "branch-group",
              name: "Branch Group",
              redirect: "branch-group",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/BranchGroup"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Branch Group Detail",
                  redirect: "/setting/branch-group",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/BranchGroup/detail"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "age-group",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "age-group",
              name: "Age Group",
              redirect: "age-group",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/AgeGroup"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Age Group Detail",
                  redirect: "/setting/age-group",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/AgeGroup/detail"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "report",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "report",
              name: "Report",
              redirect: "report",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Report"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Customer Report",
                  redirect: "/setting/report",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Report/detail"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "receipt",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "receipt",
              name: "Receipt",
              redirect: "receipt",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Receipt"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: "monitor",
                  name: "Receipt Monitor",
                  redirect: "/setting/receipt",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: ":id",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Receipt/Monitor"
                        ),
                      meta: {
                        requiresAuth: true,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    {
                      path: ":receipt_id/detail/:id",
                      name: "Receipt Monitor Detail",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Receipt/Monitor/detail"
                        ),
                      meta: {
                        requiresAuth: true,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                  ],
                },
                {
                  path: ":id",
                  name: "Receipt Detail",
                  redirect: "/setting/receipt",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Receipt/detail"
                        ),
                      meta: {
                        requiresAuth: true,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "theme",
          name: "Theme",
          component: () =>
            import("@/views/pages/setting/settingPage/SettingTheme.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "otp-log",
          name: "OTP Log",
          component: () => import("@/views/pages/setting/settingPage/OtpLog"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "/update/term",
          name: "Term And Condition",
          component: () =>
            import("@/views/pages/setting/settingPage/UpdateTerm.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "/update/policy",
          name: "Policy",
          component: () =>
            import("@/views/pages/setting/settingPage/UpdatePolicy.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "registerform",
          name: "Register Form",
          component: () =>
            import("@/views/pages/setting/settingPage/RegisterForm.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "",
          name: "",
          redirect: "plugins",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "plugins",
              name: "Plugins",
              redirect: "plugins",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/Plugins/index.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Plugins Info",
                  redirect: "/setting/plugins",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Plugins/detail.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
                {
                  path: "logs/:id",
                  name: "Plugins Log Info",
                  redirect: "/setting/plugins",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Plugins/logs.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default Setting;
