import branchType from "./branchType";
import Banner from "./banner";
import ProductBanner from "./productBanner";
import ProductMobile from "./productMobile";
import SaleChannel from "./saleChannel";
import Video from "./video";
import Privilege from "./Privilege/privilege";
import StampCard from "./Privilege/stampCard";
import Coupon from "./Privilege/coupon";
import PromotionsNews from "./promotionsNews";
import FriendGetFriend from "./Privilege/friendGetFriend";
import Mission from "./Privilege/mission";
import Notifications from "./notification";
import CampaignForms from "./campaignForms";
import Automation from "./Automations/automation";
import SendMessage from "./Automations/sendMessage";
import MemberTier from "./memberTier";
import Redeem from "./redeem";
import Customer from "./customer";
import Segment from "./segment";
import Product from "./product";
import Transaction from "./Transaction";
import ExtraPoint from "./extraPoint";
import AllRightRequest from "./allRightRequest";
import Setting from "./setting";
import ActivityLog from "./ActivityLog";
import Staff from "./Managements/staff";
import Permission from "./Managements/permission";
import Branch from "./Managements/branch";
import Role from "./Managements/role";
import Booking from "./Booking";
import Promotions from "./promotions";
import ProductAnalysis from "./productAnalysis";
import TransactionCode from "./transactionCode";
import RandomReward from "./randomReward";
import CustomObject from "./customObject";
import PoinSetting from "./pointSetting";
// import ProductCategory from "./ProductCategory";
const PATH = [
  branchType,
  Banner,
  ProductBanner,
  ProductMobile,
  SaleChannel,
  Video,
  Privilege,
  StampCard,
  Coupon,
  PromotionsNews,
  FriendGetFriend,
  Notifications,
  CampaignForms,
  Automation,
  SendMessage,
  MemberTier,
  Redeem,
  Customer,
  Segment,
  Product,
  Transaction,
  ExtraPoint,
  AllRightRequest,
  Setting,
  ActivityLog,
  Staff,
  Permission,
  Branch,
  Role,
  Booking,
  Promotions,
  ProductAnalysis,
  TransactionCode,
  CustomObject,
  RandomReward,
  PoinSetting,
  Mission,
  {
    path: "/bundle-product",
    name: "Bundle Product",
    component: () => import("@/views/pages/bundleProduct"),
    meta: { requiresAuth: true },
  },

  {
    path: "/customer-report-file",
    name: "Customer Report List",
    component: () => import("@/views/pages/customerReportFile"),
    meta: { requiresAuth: true },
  },
  {
    path: "/transaction-redeem-point",
    name: "Transaction Redeem Point",
    component: () => import("@/views/pages/transactionRedeemPoint"),
    meta: { requiresAuth: true },
  },
  {
    path: "/coupon-chart",
    name: "Coupon Analysis",
    component: () => import("@/views/pages/couponChart"),
    meta: { requiresAuth: true },
  },
  {
    path: "/redeem-chart",
    name: "Redeem Analysis",
    component: () => import("@/views/pages/redeemChart"),
    meta: { requiresAuth: true },
  },
  {
    path: "/prospect-chart",
    name: "Prospect",
    component: () => import("@/views/pages/Prospect"),
    meta: { requiresAuth: true },
  },
  {
    path: "/promotion-chart",
    name: "Promotion Analysis",
    component: () => import("@/views/pages/promotionChart"),
    meta: { requiresAuth: true },
  },
  {
    path: "/service-chart",
    name: "Service Analysis",
    component: () => import("@/views/pages/serviceChart"),
    meta: { requiresAuth: true, activeUrl: "/service-chart" },
  },
  {
    path: "/customer-analysis",
    name: "Customer Analysis",

    component: () => import("@/views/pages/dashboard"),
    meta: { requiresAuth: true, activeUrl: "/customer-analysis" },
  },
  {
    path: "/channel-analysis",
    name: "Channel Analysis",
    component: () => import("@/views/pages/channelAnalysis"),
    meta: { requiresAuth: true },
  },
  {
    path: "/welcome",
    name: "Welcome",
    redirect: "/report/customer",
    component: () => import("@/views/pages/Welcome"),
    meta: { requiresAuth: true },
  },
  {
    path: "/inventory",
    name: "inventory",

    component: () => import("@/views/pages/inventory"),
    meta: { requiresAuth: true },
  },
  {
    path: "/category",
    name: "Category",
    component: () => import("@/views/pages/category/Index"),
    meta: {
      requiresAuth: true,
      lv: 2,
      activeUrl: "/category",
      parent: "Product Mobile ",
    },
  },
  {
    path: "/automation/imagemap",
    name: "Automation Image Map",
    component: () =>
      import("@/views/pages/automation/detail/imageMap/ImageMap.vue"),
    meta: {
      requiresAuth: true,

      isChildren: true,
      activeUrl: "/automation",
    },
  },
  {
    path: "/customer/importdata",
    name: "Customer Import Data",
    component: () => import("@/views/pages/customer/import/ImportFile"),
    meta: { requiresAuth: true },
  },
  {
    path: "/customer/importpoint",
    name: "Customer Import Point",
    component: () => import("@/views/pages/customer/import/ImportPoint"),
    meta: { requiresAuth: true },
  },
  {
    path: "/customer/configdata",
    name: "Customer Config Data",
    component: () => import("@/views/pages/customer/import/configField.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/customer/checkfield",
    name: "Customer Check Data",
    component: () => import("@/views/pages/customer/import/checkField.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/product-category",
    name: "Product Category",
    component: () => import("@/views/pages/productCategory"),
    meta: { requiresAuth: true },
  },
];
export default PATH;
